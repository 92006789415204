import React from 'react'
import { withNamespaces } from 'react-i18next'
import { StyleSheet, css } from 'aphrodite'

import { Section } from 'inno-components'

import Classes from '../../styles/classes'

const styles = StyleSheet.create({
  title: {
    marginTop: '2.5em'
  },
  paragraph: {
    margin: 0
  },
  link: {
    fontWeight: 'bold',
    marginTop: '1em'
  },
  date: {
    color: '#777'
  }
})

const PresseInternet = ({ t }) => {
  const references = t('references', { returnObjects: true }).map(reference => (
    <React.Fragment key={reference.url}>
      <h2 className={css(Classes.h2, styles.title)}>{reference.nom}</h2>
      <p className={css(styles.paragraph)}>{reference.description}</p>
      {reference.date && <p className={css(styles.date)}>{reference.date}</p>}
      {reference.url && (
        <a
          className={css(Classes.primaryButton, styles.link)}
          href={reference.url}
          rel="noopener noreferrer"
          target="_blank"
        >
          {t('bouton')}
        </a>
      )}
    </React.Fragment>
  ))
  return (
    <Section bgColor="#fff" className={css(Classes.section)}>
      {references}
    </Section>
  )
}

export default withNamespaces('presse-internet')(PresseInternet)
