import React from 'react'
import { compose, pure } from 'recompose'
import { withNamespaces } from 'react-i18next'
import SetLanguageFromPage from '../../config/i18n/SetLanguageFromPage'

import SecondaryLayout from '../../components/layouts/SecondaryLayout'
import PresseInternet from '../../components/references/PresseInternet'

const PresseInternetPage = ({ t }) => (
  <SecondaryLayout subtitle={t('titre')} page="ComponentReferencesPresseInternet">
    <PresseInternet />
  </SecondaryLayout>
)

const echance = compose(
  pure,
  SetLanguageFromPage(),
  withNamespaces('presse-internet')
)

export default echance(PresseInternetPage)
